var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.berechtigungen.m_finanzen.read)?_c('div',[_c('div',{staticClass:"row pt-1"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"tile-categorie-frame"},[_c('div',{staticClass:"tile-categorie"},[_c('div',{staticClass:"header"},[_c('span',[_vm._v(_vm._s(_vm.$t("global.dashboard_registrations")))])]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12 col-lg-12 col-md-12"},[_c('pie-chart',{attrs:{"data":_vm.statistikAnmeldungen,"colors":[
                    '#add58c',
                    '#1ad7e1',
                    '#ade0ec',
                    '#c3c4e2',
                    '#fccb9d',
                    '#f4f3d5',
                  ],"legend":false}})],1)])])])])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }